import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import Spinner from '../components/Spinner'
import { Content } from '../layouts/content'
import { Sidebar } from '../layouts/sidebar'
import { useAuth } from './AuthContext'

type Props = {
  children?: React.ReactNode
}

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth()
  const location = useLocation()

  if (isLoading) {
    return (
      <div>
        <Spinner size="page" className="text-primary" />
      </div>
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  return (
    <Sidebar>
      <Content>{children ? children : <Outlet />}</Content>
    </Sidebar>
  )
}

export default PrivateRoute
