import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AuthProvider } from './context/AuthContext'
import { ClientProvider } from './context/ClientContext'
import { ConfigProvider } from './context/ConfigContext'
import { SessionProvider } from './context/SessionContext'
import { ThemeProvider } from './context/ThemeContext'
import Router from './pages/router'

const queryClient = new QueryClient()

export function App() {
  return (
    <div className="bg-background text-on-background h-full">
      <ConfigProvider>
        <ThemeProvider>
          <AuthProvider>
            <ClientProvider>
              <QueryClientProvider client={queryClient}>
                <SessionProvider>
                  <Router />
                </SessionProvider>
              </QueryClientProvider>
            </ClientProvider>
          </AuthProvider>
        </ThemeProvider>
      </ConfigProvider>
    </div>
  )
}

export default App
