import { ReactNode, createContext, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Asset,
  AssetSet,
  AssetWithPresignedUrl,
  GetAssetInput,
  GetAssetSetInput,
} from '@droidmap/asset-service-contract'
import { GetProjectInput, Project } from '@droidmap/project-service-contract'
import { kebabToCamel } from '@droidmap/shared/utils'

import usePathQuery from '../hooks/usePathQuery'
import { QueryFn } from '../hooks/usePathQuery/types'
import { defaultPathMatcher } from '../hooks/usePathQuery/utils'
import { useClient } from './ClientContext'

export interface RouteContext {
  type: string
  id: string
  name: string
}

interface UseSession {
  routeMetadata: RouteContext[]
}

interface QueryFunctions {
  asset: QueryFn<GetAssetInput, Asset | AssetWithPresignedUrl>
  assetSets: QueryFn<GetAssetSetInput, AssetSet>
  projects: QueryFn<GetProjectInput, Project>
}

const Session = createContext<UseSession>({ routeMetadata: [] })

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const clients = useClient()
  const { pathname } = useLocation()

  const {
    data = [],
    isLoading,
    isError,
    error,
  } = usePathQuery({
    queryPath: pathname,
    queryKey: ['breadcrumbs', pathname],
    queryFn: {
      asset: {
        queryPath: ['/asset-set/:assetId', '/asset/:assetId', '/maps/:assetId'],
        queryConfig: (inputData) => ({
          queryKey: ['assetSet', inputData.assetId, clients.assetClient],
          queryFn: () => clients.assetClient.getAsset(inputData),
          related: {
            queryFn: 'assetSets',
            queryInput: ({ assetSetId }) => ({ assetSetId }),
          },
        }),
      },
      assetSets: {
        queryPath: ['/asset-sets/:assetSetId'],
        queryConfig: (inputData) => ({
          queryKey: ['assetSets', inputData.assetSetId],
          queryFn: () => clients.assetClient.getAssetSet(inputData),
          related: {
            queryFn: 'projects',
            queryInput: ({ projectId }) => ({ projectId }),
          },
        }),
      },
      projects: {
        queryPath: ['/projects/:projectId'],
        queryConfig: (inputData) => ({
          queryKey: ['project', inputData.projectId],
          queryFn: () => clients.projectClient.getProject(inputData),
        }),
      },
    } satisfies QueryFunctions,
    // This transform is applied after the data is first saved to ensure
    // the the data is initially saved as is.
    postTransformQueryResult: (
      { id, name, assetType },
      { queryKey: [type] },
    ) => ({
      type: assetType || kebabToCamel(type as string),
      id,
      name,
    }),
    pathMatcher: (pattern, path) => {
      if (path === '/asset-sets/create') {
        return null
      }
      return defaultPathMatcher(pattern, path)
    },
    enabled: clients.projectClient !== undefined,
  })

  if (isLoading || isError) {
    if (error) {
      console.error(error)
    }
    return null
  }

  const routeMetadata = data as unknown as RouteContext[]

  return (
    <Session.Provider value={{ routeMetadata }}>{children}</Session.Provider>
  )
}

export const useSession = (): UseSession => {
  const context = useContext(Session)

  if (context === undefined) {
    throw new Error('useSession must be used within an SessionProvider')
  }
  return context
}
