import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AuthForm from '../../components/AuthForm'
import AuthPage from '../../components/AuthPage'
import { Button } from '../../components/Button'
import ErrorMessage from '../../components/ErrorMessage'
import Input from '../../components/Input'
import { UserSignInInput, useAuth } from '../../context/AuthContext'
import { useFormData } from '../../hooks/useFormData'

type RedirectLocationState = {
  from: Location
}

const initialSignInInput: UserSignInInput = {
  username: '',
  password: '',
}

export default function Login() {
  const auth = useAuth()
  const navigate = useNavigate()
  const [userDetails, handleUserDetailsChange] =
    useFormData<UserSignInInput>(initialSignInInput)
  const [error, setError] = useState<string | undefined>()
  const { state: locationState } = useLocation()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError('')
    setLoading(true)

    try {
      const result = await auth.signIn(userDetails)
      if (result.success) {
        if (locationState) {
          const { from } = locationState as RedirectLocationState
          // Until we have a dashboard we are redirecting to projects
          const pathname = from.pathname === '/' ? '/projects' : from.pathname
          navigate(pathname)
        } else {
          navigate('/projects')
        }
      } else {
        setError(result.message)
      }
    } catch (err: unknown) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  }

  const inputFields = [
    {
      label: 'Username / Email address',
      type: 'text',
      name: 'username',
      placeholder: 'Username',
      // Match any Unicode letter, mark, symbol, number, or punctuation,
      // as per https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
      pattern: '[\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      placeholder: 'Password',
    },
  ]

  return (
    <AuthPage title="Sign in to your account">
      <AuthForm onSubmit={handleSubmit}>
        {inputFields.map(({ label, name, type, placeholder, pattern }) => (
          <Input
            key={name}
            label={label}
            name={name}
            type={type}
            placeholder={placeholder}
            value={userDetails[name as keyof UserSignInInput]}
            required
            onChange={handleUserDetailsChange}
            pattern={pattern}
          />
        ))}

        <div className="text-sm">
          <a
            href="/forgot-password"
            className="font-semibold text-primary hover:text-primary-light"
          >
            Forgot password?
          </a>
        </div>

        {error && <ErrorMessage content={error} />}
        <Button type="submit" className="w-full" isLoading={loading}>
          Sign in
        </Button>
      </AuthForm>

      <p className="text-on-background mt-10 text-center text-sm">
        Don't have an account?{' '}
        <a
          href="/signup"
          className="font-semibold leading-6 text-primary hover:text-primary-light"
        >
          Sign up here
        </a>
      </p>
    </AuthPage>
  )
}
