import { HomeIcon } from '@heroicons/react/24/solid'
import { generatePath } from 'react-router-dom'

import { BreadcrumbItem } from '../../components/Breadcrumb'
import { AuthState } from '../../context/AuthContext'
import { RouteContext } from '../../context/SessionContext'
import { getPageDataById, getPageDataByType } from '../../pages/pagesData'

/**
 * Formats a given path by replacing a name id placeholder with a generic :id.
 *
 * @param path - The path string that may contain a placeholder (e.g., ':id').
 * @param id - The id to replace the placeholder with.
 * @returns The formatted path with the id inserted, or the original path if no placeholder is found.
 */
export const formatPath = (path: string, id: string) => {
  if (!path.includes(':')) {
    return path
  }
  // use generic pathname for id (:id)
  const normalizedPath = [path.split(':')[0], ':id'].join('')
  return generatePath(normalizedPath, { id })
}

/**
 * Generates breadcrumb navigation data from the given route metadata.
 *
 * @param {RouteContext[]} routeMetadata - An array of route context objects containing metadata for each route.
 * @returns {BreadcrumbItem[]} An array of breadcrumb objects with title, path, and active status.
 *
 * Each breadcrumb object contains:
 * - `title`: The name of the route.
 * - `path`: The formatted path of the route.
 * - `active`: A boolean indicating if the breadcrumb is the current active route.
 */
export const getCrumbs = (routeMetadata: RouteContext[]): BreadcrumbItem[] => {
  const count = routeMetadata.length - 1
  const crumbs = [...routeMetadata]
    .reverse()
    .map(({ type, id, name }, index) => {
      const pageData = getPageDataByType(type)

      if (!pageData) {
        return []
      }

      const parent = pageData?.parent
        ? getPageDataById(pageData.parent)
        : undefined

      return [
        ...(parent
          ? [
              {
                path: formatPath(parent.path, id),
                title: parent.title,
                active: parent.path.includes(':'),
              },
            ]
          : []),
        {
          title: name,
          path: formatPath(pageData.path, id),
          active: index === count,
        },
      ]
    })
    .filter(Boolean)

  return crumbs.flat()
}

/**
 * Generates a breadcrumb item representing the root of the application.
 *
 * @param path - The current path of the application.
 * @param authContext - An object containing the username and active organization of the authenticated user.
 * @returns A `BreadcrumbItem` object representing the root of the application.
 */
export const getRoot = (
  path: string,
  authContext: AuthState,
): BreadcrumbItem => {
  return {
    icon: HomeIcon,
    title:
      authContext.username === authContext.activeOrganisation.name
        ? 'My Organization'
        : authContext.activeOrganisation.name,
    path: '/',
    active: path === '/projects',
  }
}
