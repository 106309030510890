import {
  AssetServiceClient,
  AssetSet,
  AssetSetType,
  AssetWithPresignedUrl,
  ImageAssetSetStats,
  PresignedUrlType,
} from '@droidmap/asset-service-contract'
import { DroidMapMetadata } from '@droidmap/shared/service-contract'
import { DroidMapError } from '@droidmap/shared/utils'

import { OdmSettings } from './odm-settings'

export type MappingJobSettings = {
  vCpu: number
  memory: number
  odmSettings: OdmSettings
}

export const calculateJobSettings = async (
  assetServiceClient: AssetServiceClient,
  inputAssetSets: string[],
  odmSettings: OdmSettings,
  metadata?: DroidMapMetadata,
): Promise<MappingJobSettings> => {
  const inputImageStats = await calculateImageAssetSetStats(
    inputAssetSets,
    assetServiceClient,
    metadata,
  )

  let vCpu = 4
  let memory = 8

  // Experiment to see if we can find a metric for determining the
  // amount of compute needed for a job that takes into account that
  // images can be of different resolutions
  const gigapixels = inputImageStats.totalPixelsInMegapixels / 1000

  switch (true) {
    // 250 x 12MP images
    // 150 x 20MP images
    // 62 x 48MP images
    // cxx.xlarge
    case gigapixels < 3:
      vCpu = 4
      memory = 8
      break
    // 500 x 12MP images
    // 300 x 20MP images
    // 125 x 48MP images
    // cxx.2xlarge
    case gigapixels < 6:
      vCpu = 8
      memory = 16
      break
    // 1000 x 12MP images
    // 600 x 20MP images
    // 250 x 48MP images
    // cxx.4xlarge
    case gigapixels < 12:
      vCpu = 16
      memory = 32
      break
    // 2000 x 12MP images
    // 1200 x 20MP images
    // 500 x 48MP images
    // cxx.8xlarge
    case gigapixels < 24:
      vCpu = 32
      memory = 64
      break
    // 3000 x 12MP images
    // 1800 x 20MP images
    // 750 x 48MP images
    // cxx.12xlarge
    case gigapixels < 36:
      vCpu = 32
      memory = 64
      break
    // 4000 x 12MP images
    // 2400 x 20MP images
    // 1000 x 48MP images
    // cxx.16xlarge
    case gigapixels < 48:
      vCpu = 64
      memory = 128
      break
    // 8000 x 12MP images
    // 4800 x 20MP images
    // 2000 x 48MP images
    // cxx.24xlarge
    case gigapixels < 96:
      vCpu = 96
      memory = 192
      break
    // 10666 x 12MP images
    // 6400 x 20MP images
    // 2667 x 48MP images
    // cxx.32xlarge
    case gigapixels < 128:
      vCpu = 128
      memory = 256
      break
    // 21333 x 12MP images
    // 12800 x 20MP images
    // 5333 x 48MP images
    // mxx.32xlarge
    case gigapixels < 256:
      vCpu = 128
      memory = 512
      break
    // 42666 x 12MP images
    // 25600 x 20MP images
    // 10666 x 48MP images
    // rxx.32xlarge
    case gigapixels < 512:
      vCpu = 128
      memory = 1024
      break
    default:
      throw new DroidMapError(
        'Too many images for the current implementation.',
        { context: { inputImageStats } },
      )
  }

  // Update the odm settings to set max concurrency based on the number of vCPUs
  const updatedOdmSettings = setParallelism(odmSettings, vCpu)

  return {
    vCpu,
    memory: GiBToBatchMiB(memory),
    odmSettings: updatedOdmSettings,
  }
}

/**
 * Convert the number of GiB of memory given for an ec2 instance type
 * to the number of MiB of memory that should be requested for a batch job
 * we want to run on that instance.
 *
 * There is an added complication in that ECS reserves some memory for
 * itself and critical system processes. So we need to take that into account.
 * The amount of memory that an instance makes available for batch jobs
 * is not a consistent percentage across instance types. So we will use
 * a rough estimate based on worst case observations that up to 10% of the
 * memory is not available for batch jobs.
 *
 * @param gib The number of GiB of memory for an ec2 instance type
 */
export const GiBToBatchMiB = (gib: number): number => {
  const MIB_IN_GIB = 1024
  const totalMiB = gib * MIB_IN_GIB

  // The docs say that ECS reserves 32 MiB of memory for itself
  // and critical system processes.
  // Will make it 64 MiB to be safe and try to ensure we don't
  // accidentally get bumped to a larger instance type than we need.
  const ECS_RESERVED_MEMORY = 64

  // However we have observed that the memory reported by the instance
  // in ecs is between 91-95% of the max memory for the instance type.
  // We will assume 10% is unavailable to be safe.
  const OBSERVED_RESERVED_MEMORY_PERCENTAGE = 0.1

  // So will use the max of those two values.
  const reservedMiB = Math.max(
    ECS_RESERVED_MEMORY,
    totalMiB * OBSERVED_RESERVED_MEMORY_PERCENTAGE,
  )

  return Math.floor(totalMiB - reservedMiB)
}

/**
 * Update the odm settings to set max concurrency based on the number of vCPUs
 * we are requesting for the batch job.
 *
 * We have observed that ODM uses memory in line with the number of concurrent
 * operations or threads. If you try to do to many operations in parallel you can
 * easily run out of memory.
 *
 * This is particularly annoying for AWS Batch where you may end up on a larger
 * instance size than you requested with more cpus, but the asked for amount of
 * memory. In this scenario ODM by default will try to parallelize across all
 * available cpus and quickly run out of memory. Capping the concurrency here
 * to our requested number of cpus will prevent us running out memory but means
 * we don't get the full benefit of the larger instance size.
 */
export const setParallelism = (odmSettings: OdmSettings, vCpu: number) => {
  return {
    ...odmSettings,
    stageOptions: {
      general: {
        maxConcurrency: vCpu,
      },
    },
  }
}

/**
 * Calculate summary stats for an asset set used to hold images.
 *
 * These stats can be used to estimate what the compute requirements
 * for a photogrammetry job might be.
 *
 * @returns The stats for the image asset sets
 */
export const calculateImageAssetSetStats = async (
  inputAssetSets: string[],
  assetServiceClient: AssetServiceClient,
  metadata?: DroidMapMetadata,
): Promise<ImageAssetSetStats> => {
  // Look up the asset sets
  const assetSets: AssetSet[] = []
  for (const inputAssetSetId of inputAssetSets) {
    const assetSet = await assetServiceClient.getAssetSet(
      {
        assetSetId: inputAssetSetId,
      },
      metadata,
    )
    if (!assetSet) {
      throw new DroidMapError('Asset set not found', {
        context: { inputAssetSetId },
      })
    }
    assetSets.push(assetSet)
  }

  // We are only interested in AssetSets of type DRONE_IMAGES
  const droneImageSets = assetSets.filter(
    (assetSet) => assetSet.assetSetType === AssetSetType.DroneImages,
  )
  if (droneImageSets.length === 0) {
    throw new DroidMapError('No drone image sets found')
  }

  // Iterate over the assets in the asset sets and calculate the stats
  let totalImages = 0
  let totalPixelCount = 0
  let totalSizeInBytes = 0
  let uniform = true
  let imageWidth = 0
  let imageHeight = 0
  let imagePixels = 0

  for (const droneImageSet of droneImageSets) {
    let nextToken: object | undefined = undefined
    do {
      const { items, next } = await assetServiceClient.listAssetsByAssetSet(
        {
          assetSetId: droneImageSet.id,
          presignedUrlType: PresignedUrlType.GET,
          next: nextToken,
        },
        metadata,
      )

      for (const image of items) {
        const { size, width, height } = validateImage(image)
        const pixels = width * height

        totalImages++
        totalSizeInBytes += size
        totalPixelCount += pixels

        if (uniform) {
          if (imageWidth === 0 && imageHeight === 0) {
            imageWidth = width
            imageHeight = height
          } else if (imageWidth !== width || imageHeight !== height) {
            uniform = false
          }
        }

        if (width > imageWidth) {
          imageWidth = width
        }

        if (height > imageHeight) {
          imageHeight = height
        }

        if (imagePixels === 0) {
          imagePixels = pixels
        } else if (pixels > imagePixels) {
          imagePixels = pixels
        }
      }

      nextToken = next
    } while (nextToken)
  }

  const pixelsToMegapixels = (pixels: number): number => {
    return pixels / 1000000
  }

  return {
    totalImages,
    totalPixelsInMegapixels: pixelsToMegapixels(totalPixelCount),
    totalSizeInBytes,
    uniform,
    imageDimensions: {
      width: imageWidth,
      height: imageHeight,
    },
    imageMegapixel: pixelsToMegapixels(imagePixels),
  }
}

export const validateImage = (
  asset: AssetWithPresignedUrl,
): { size: number; width: number; height: number } => {
  if (!asset.uploadConfirmed) {
    throw new DroidMapError('Asset has not been uploaded.', {
      context: { asset },
    })
  }

  const { size, dimensions } = asset

  if (!size) {
    throw new DroidMapError('Asset does not have size set.', {
      context: { asset },
    })
  }

  if (!dimensions) {
    throw new DroidMapError('Asset does not have dimensions set.', {
      context: { asset },
    })
  }

  if (!dimensions.width || !dimensions.height) {
    throw new DroidMapError('Asset does not have dimensions set.', {
      context: { asset },
    })
  }

  return {
    size,
    width: dimensions.width,
    height: dimensions.height,
  }
}
