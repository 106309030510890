import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

type IconType = React.ComponentType<
  React.PropsWithoutRef<React.ComponentProps<'svg'>> & {
    title?: string | undefined
    titleId?: string | undefined
  }
>

type BreadcrumbItem = {
  id?: string
  title: string
  path?: string
  parentPath?: string
  icon?: IconType
  items?: BreadcrumbMenuItem[]
  active?: boolean
}

interface BreadcrumbMenuItem extends Omit<BreadcrumbItem, 'items'> {
  path: string
}

interface BreadcrumbMenuProps {
  title: string
  items: BreadcrumbMenuItem[]
}

const BreadcrumbLink = ({ active, title, path, icon: Icon }: BreadcrumbItem) =>
  path && !active ? (
    <Link
      to={path}
      className="flex whitespace-nowrap items-center hover:text-[#0060ff]"
    >
      {Icon && (
        <span className="mr-3">
          <Icon className="h-5 w-5" />
        </span>
      )}
      {title}
    </Link>
  ) : (
    <span className="flex items-center whitespace-nowrap">
      {Icon && (
        <span className="mr-3">
          <Icon className="h-5 w-5" />
        </span>
      )}
      {title}
    </span>
  )

const BreadcrumbMenu = ({ title, items }: BreadcrumbMenuProps) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton className="flex items-center gap-2 focus:outline-none">
            {title}
            <ChevronDownIcon className={clsx('size-3', open && 'rotate-180')} />
          </PopoverButton>
          <PopoverPanel className="absolute left-1/2 transform -translate-x-1/2 z-10 mt-2 w-48 bg-white shadow-lg border border-gray-200 rounded-md">
            <div className="flex flex-col pt-3 px-3">
              {items.map(({ title, path, icon }, i) => (
                <div className="mb-3">
                  <BreadcrumbLink
                    key={i}
                    title={title}
                    path={path}
                    icon={icon}
                  />
                </div>
              ))}
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  )
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]
  separator?: IconType
}

const DEFAULT_SEPARATOR = ChevronRightIcon

const Breadcrumb = ({
  items = [],
  separator: SeparatorIcon = DEFAULT_SEPARATOR,
}: BreadcrumbProps) => {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="flex select-none">
        {items.map(({ items = [], ...item }, index) => {
          return (
            <li key={index} className="flex items-center relative">
              {index !== 0 && (
                <span className="mx-3">
                  <SeparatorIcon className="h-3 w-3" aria-hidden="true" />
                </span>
              )}
              <div className="flex items-center group">
                {items.length === 0 && <BreadcrumbLink {...item} />}
                {items.length > 0 && (
                  <BreadcrumbMenu title={item.title} items={items} />
                )}
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export { Breadcrumb, BreadcrumbItem }
