import { Location, Navigate, Route, Routes } from 'react-router-dom'

import { PrivateRoute } from '../context/PrivateRoute'
import { PublicRoute } from '../context/PublicRoute'
import pagesData from './pagesData'

type NavigationState = {
  signup?: {
    username: string
  }
}

export type RouterLocation = Location<NavigationState>

const Router = () => {
  const pageRoutes = pagesData.map((page) => {
    const Guard = page.public ? PublicRoute : PrivateRoute

    return (
      <Route
        key={page.title}
        path={page.path}
        element={<Guard>{page.element}</Guard>}
      />
    )
  })
  return (
    <Routes>
      {pageRoutes}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default Router
