import { useCallback, useEffect, useState } from 'react'

import { QueryResult, UsePathQueryOptions } from './types'
import {
  defaultPathMatcher,
  defaultQueryClient,
  findQueryConfigByPath,
  resolveQueryAndRelated,
} from './utils'

/**
 * The initial state for the usePathQuery hook.
 *
 * - `data`: An empty array to store the resolved query data.
 * - `isLoading`: A boolean indicating whether the query is currently loading.
 * - `isError`: A boolean indicating whether the query has encountered an error.
 * - `error`: An error object or undefined if no error has occurred.
 */
type State = {
  data: QueryResult[]
  isLoading: boolean
  isError: boolean
  error?: Error
}

const initialState: State = {
  data: [],
  isLoading: false,
  isError: false,
  error: undefined,
}

/**
 * A custom React hook to manage nested and related queries using @tanstack/react-query.
 *
 * @param options - Configuration options for the hook.
 * @returns The state of the query, including data, loading, and error states.
 *
 * Example:
 *
 * const queries = {
 *   user: (input) => ({
 *     queryKey: ['user', input.userId],
 *     queryFn: () => fetchUser(input.userId),
 *     related: {
 *       query: 'posts',
 *       queryInput: (data) => ({ userId: data.id }),
 *     },
 *   }),
 *   posts: (input) => ({
 *     queryKey: ['posts', input.userId],
 *     queryFn: () => fetchPosts(input.userId),
 *   }),
 * };
 *
 * const { data, isLoading, isError, error } = usePathQuery({
 *   queryPath: 'user',
 *   queryFn: queries,
 *   queryKey: ['combinedData'],
 *   initialQueryInput: { userId: 1 },
 * });
 */
const usePathQuery = ({
  queryPath,
  queryFn,
  queryKey,
  initialQueryInput = {},
  postTransformQueryResult = (result) => result, // Default to identity function,
  pathMatcher = defaultPathMatcher,
  queryClient = defaultQueryClient,
  enabled = true,
}: UsePathQueryOptions) => {
  const [state, setState] = useState<State>(initialState)

  const resolveQueries = useCallback(async () => {
    setState({ ...initialState, isLoading: true })

    try {
      const queryConfig = findQueryConfigByPath(queryPath, {
        queryFn,
        pathMatcher,
        initialQueryInput,
      })

      if (queryConfig) {
        const allResults = await resolveQueryAndRelated(queryConfig, {
          queryFn,
          postTransformQueryResult,
          queryClient,
        })

        if (queryKey) {
          queryClient.setQueryData(queryKey, allResults)
        }

        setState({
          data: allResults,
          isLoading: false,
          isError: false,
          error: undefined,
        })
      } else {
        console.warn(`No matching query found for queryPath: '${queryPath}'`)
        setState({ ...initialState, isLoading: false })
      }
    } catch (error) {
      console.error('Error resolving queries for queryPath:', queryPath, error)
      setState({
        data: [],
        isLoading: false,
        isError: true,
        error: error as Error,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPath])

  useEffect(() => {
    if (!enabled) {
      return
    }
    resolveQueries()
  }, [queryPath, enabled, resolveQueries])

  return state
}

export default usePathQuery
