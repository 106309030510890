import { useLocation } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb'
import { Card, CardContent } from '../../components/Card'
import { useAuth } from '../../context/AuthContext'
import { useSession } from '../../context/SessionContext'
import { getPageDataByPath } from '../../pages/pagesData'
import { getCrumbs, getRoot } from './utils'

type ContentProps = {
  children?: React.ReactNode
}

export const Content: React.FC<ContentProps> = ({ children }) => {
  const { pathname } = useLocation()
  const { routeMetadata } = useSession()
  const authState = useAuth()

  const currentPage = getPageDataByPath(pathname)

  const crumbs =
    routeMetadata.length > 0
      ? getCrumbs(routeMetadata)
      : [{ ...currentPage, active: true }]

  const breadcrumbs = [
    getRoot(pathname, authState),
    ...crumbs,
  ] as BreadcrumbItem[]

  return (
    <>
      <div className="flex flex-col space-y-6 mb-6">
        <Card className="rounded-lg shadow-lg">
          <CardContent className="px-4 pb-3 pt-3 relative">
            <div className="overflow-x-auto group no-scrollbar">
              <Breadcrumb items={breadcrumbs} />
            </div>
            {/* Left Gradient */}
            <div className="absolute inset-y-0 left-3 w-6 bg-gradient-to-r from-surface to-transparent pointer-events-none sm:hidden"></div>
            {/* Right Gradient */}
            <div className="absolute inset-y-0 right-3 w-6 bg-gradient-to-l from-surface to-transparent pointer-events-none"></div>
          </CardContent>
        </Card>
      </div>
      {children}
    </>
  )
}
