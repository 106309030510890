import { QueryClient } from '@tanstack/react-query'
import { matchPath } from 'react-router-dom'

import {
  PickMakeRequired,
  QueryConfig,
  QueryResult,
  ResolveQueryOptions,
  UsePathQueryOptions,
} from './types'

/**
 * A singleton instance of QueryClient for managing query caching and fetching.
 */
export const defaultQueryClient = new QueryClient()

/**
 * Matches a given path against a specified pattern.
 *
 * @param pattern - The pattern to match the path against.
 * @param path - The path to be matched.
 * @returns A match result object if the path matches the pattern, otherwise null.
 */
export const defaultPathMatcher = (pattern: string, path: string) =>
  matchPath({ path: pattern }, path)

/**
 * Finds the query configuration by matching the provided query path.
 *
 * @param queryPath - The path to match against the query functions.
 * @param options - The options object containing required properties.
 * @param options.queryFn - An object where keys are paths and values are query functions.
 * @param options.pathMatcher - A function to match the key with the query path.
 * @param options.initialQueryInput - The initial input for the query.
 * @returns The query configuration if a match is found, otherwise `undefined`.
 */
export const findQueryConfigByPath = (
  queryPath: string,
  {
    queryFn,
    pathMatcher,
    initialQueryInput,
  }: PickMakeRequired<
    UsePathQueryOptions,
    'queryFn' | 'pathMatcher' | 'initialQueryInput'
  >,
): QueryConfig | undefined => {
  for (const key in queryFn) {
    const queryFunction = queryFn[key]
    for (const path of queryFunction.queryPath) {
      const match = pathMatcher(path, queryPath)

      if (match) {
        const queryInput = {
          ...initialQueryInput,
          ...match.params,
        }

        return queryFunction.queryConfig(queryInput)
      }
    }
  }
  return undefined
}

/**
 * Resolves a query and its related queries.
 *
 * @param {QueryConfig} query - The configuration for the query to be resolved.
 * @param {ResolveQueryOptions} options - Options for resolving the query.
 * @returns {Promise<QueryResult[]>} A promise that resolves with the combined results of the query and its related queries.
 * @throws Will throw an error if the query or related queries cannot be resolved.
 */
export const resolveQueryAndRelated = async (
  query: QueryConfig,
  options: ResolveQueryOptions,
): Promise<QueryResult[]> => {
  try {
    return await resolveQuery(query, options)
  } catch (error) {
    console.error('Error resolving query or related queries:', {
      queryKey: query.queryKey,
      error,
    })
    throw error
  }
}

/**
 * Resolves a query and its related queries, if any, and returns the results.
 *
 * @param {QueryConfig} config - The configuration for the query.
 * @param {Function} config.queryFn - The function to execute the main query.
 * @param {string} config.queryKey - The key to identify the main query.
 * @param {Object} [config.related] - The configuration for related queries.
 * @param {ResolveQueryOptions} options - The options for resolving the query.
 * @param {QueryClient} options.queryClient - The query client to manage query cache.
 * @param {Function} options.postTransformQueryResult - The function to transform the query result.
 * @returns {Promise<QueryResult[]>} - A promise that resolves to an array of query results.
 */
export const resolveQuery = async (
  { queryFn: query, queryKey, related }: QueryConfig,
  options: ResolveQueryOptions,
): Promise<QueryResult[]> => {
  const results: QueryResult[] = []
  const { queryClient, queryFn, postTransformQueryResult } = options

  // Resolve the main query
  let rootResult = queryClient.getQueryData(queryKey)

  if (!rootResult) {
    rootResult = await query()
    queryClient.setQueryData(queryKey, rootResult)
  }

  // Apply the transformation function if provided
  results.push(
    postTransformQueryResult(rootResult as QueryResult, { queryKey }),
  )

  // Resolve related queries if any
  if (related) {
    const relatedResults = await resolveQuery(
      queryFn[related.queryFn].queryConfig(related.queryInput?.(rootResult)),
      options,
    )
    results.push(...relatedResults)
  }

  return results
}
