import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthForm from '../../components/AuthForm'
import AuthPage from '../../components/AuthPage'
import { Button } from '../../components/Button'
import ErrorMessage from '../../components/ErrorMessage'
import Input from '../../components/Input'
import { UserSignUpInput, useAuth } from '../../context/AuthContext'
import { useFormData } from '../../hooks/useFormData'
import { RouterLocation } from '../router'

const initialUserDetails: UserSignUpInput = {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  password: '',
}

export default function SignUp() {
  const navigate = useNavigate()
  const auth = useAuth()
  const [userDetails, handleUserDetailsChange] =
    useFormData<UserSignUpInput>(initialUserDetails)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleError = (message: string | undefined) => {
    setError(message || 'An unknown error occurred')
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault()
    setError('')
    setLoading(true)

    try {
      const { success, message, nextStep } = await auth.signUp(userDetails)

      if (!success) {
        handleError(message)
        return
      }

      switch (nextStep) {
        case 'CONFIRM_SIGN_UP':
          navigate('/confirm-signup', {
            state: {
              signup: {
                username: userDetails.username,
              },
            } as RouterLocation['state'],
          })
          break
        case 'COMPLETE_AUTO_SIGN_IN':
          {
            const signInOutput = await auth.autoSignIn()
            if (signInOutput.isSignedIn) {
              navigate('/profile')
            }
          }
          break
        case 'DONE':
          navigate('/profile')
          break
        default:
          throw new Error('Unexpected sign up step')
      }
    } catch (err: unknown) {
      handleError(err instanceof Error ? err.message : undefined)
    } finally {
      setLoading(false)
    }
  }

  const inputFields = [
    {
      label: 'Email address',
      name: 'email',
      type: 'email',
      placeholder: 'Email',
    },
    {
      label: 'Username',
      name: 'username',
      type: 'text',
      placeholder: 'Username',
      pattern: '[\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+',
    },
    {
      label: 'First name',
      name: 'firstName',
      type: 'text',
      placeholder: 'First Name',
    },
    {
      label: 'Last name',
      name: 'lastName',
      type: 'text',
      placeholder: 'Last name',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      placeholder: 'Password',
    },
  ]

  return (
    <AuthPage title={'Sign up'}>
      <AuthForm onSubmit={handleSubmit}>
        {inputFields.map(({ label, name, type, placeholder, pattern }) => (
          <Input
            key={name}
            label={label}
            name={name}
            type={type}
            placeholder={placeholder}
            value={userDetails[name as keyof UserSignUpInput]}
            required
            onChange={handleUserDetailsChange}
            pattern={pattern}
          />
        ))}

        {error && <ErrorMessage content={error} />}

        <Button
          type="submit"
          className="w-full"
          isLoading={loading}
          disabled={loading}
        >
          Sign up
        </Button>
      </AuthForm>
      <p className="text-on-background mt-10 text-center text-sm">
        Already have an account?{' '}
        <a
          href="/login"
          className="font-semibold leading-6 text-primary hover:text-primary-light"
        >
          Login here
        </a>
      </p>
    </AuthPage>
  )
}
